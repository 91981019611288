import React from "react";

const Imagen = () => {
  return (
    <div data-aos="zoom-in" className="container">
      <div className="image-container">
        {/* Image 1 */}
        <div className="image-wrapper">
          <img
            className="round-image"
            src={`${process.env.PUBLIC_URL}/programacion.png`}
            alt="Placeholder 1"
          />
          <h1 className="image-text">Programación a medida</h1>
        </div>
          
        {/* Image 2 */}
        <div className="image-wrapper">
          <img
            className="round-image"
            src={`${process.env.PUBLIC_URL}/infra.png`}
            alt="Placeholder 2"
          />
          <h1 className="image-text">Infraestructura</h1>
        </div>
      
        
        {/* Image 3 */}
        <div className="image-wrapper">
          <img
            className="round-image"
            src={`${process.env.PUBLIC_URL}/gestion.png`}
            alt="Placeholder 3"
          />
          <h1 className="image-text">Sistemas de Gestión</h1>
        </div>
        
        {/* Image 4 */}
        <div className="image-wrapper">
          <img
            className="round-image"
            src={`${process.env.PUBLIC_URL}/sku.png`}
            alt="Placeholder 4"
          />
          <h1 className="image-text">Venta de Equipos para control</h1>
        </div>
      </div>
      <br></br>
    <br></br>

    </div>
  );
};

export default Imagen;
