import  {React, useState}from 'react';
import { Container, Navbar, Nav, Carousel } from 'react-bootstrap';
import Imagen from './imagen';
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faChartLine, faCloud, faFileInvoice, faMobileAlt, faMoneyBill,faPhone, faEnvelope, faMapMarkerAlt  } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos CSS de AOS


function App() {
  const [isZoomed, setIsZoomed] = useState(false);
  AOS.init({
    duration: 1000 // Duración de todas las animaciones en milisegundos
  });

  const handleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const h1Style = {
    flex: '1', /* Hace que el contenedor de texto ocupe todo el espacio disponible */
    paddingRight: '50px', /* Añade espacio entre el texto y la imagen */
    textAlign: 'center', /* Centra el texto horizontalmente */
    color: 'white', /* Establece el color del texto en blanco */
    fontFamily: 'Montserrat, sans-serif',
    fontOpticalSizing: 'auto',
    /* Agregar sombra */
    textShadow: '2px 4px 30px rgba(0, 0, 40, 0.1)',
  };


  
  return (
    <div>
      <Navbar style={{ background: 'linear-gradient(45deg, #6a0dad, #9b30ff)', boxShadow: '0 4px 20px rgba(0,0,0,0.2)' }} variant="dark" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="#home">SistemasGenius</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="#Nosotros" style={{ color: 'white' }} className="menu-link">Nosotros</Nav.Link>
              <Nav.Link href="#Genius" style={{ color: 'white' }} className="menu-link">Genius System</Nav.Link>
              <Nav.Link href="#servicios" style={{ color: 'white' }} className="menu-link">Servicios</Nav.Link>
              <Nav.Link href="#Contacto" style={{ color: 'white' }} className="menu-link">Contacto</Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Carousel className="carousel" interval={2000} style={{ height: '400px' }} controls={false} indicators={false}>        
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={`${process.env.PUBLIC_URL}/D12.gif`} // Ruta de tu imagen GIF
      alt="First slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={`${process.env.PUBLIC_URL}/carru3.gif`}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={`${process.env.PUBLIC_URL}/carru4.gif`}
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL}/fondi2x.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: '50px',
        minHeight: '100vh',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)'
      }}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>


        <div  id='Nosotros' data-aos="fade-up">
    
      
          
       
  <h1  className='hola' style={{ textAlign: 'center' }}>Bienvenido a Sistemas Genius</h1>
  <br />
  <h5 style={{ textAlign: 'center' }} data-aos="fade-up">
    En sistemas Genius somos una empresa de desarrollo de software, especializada en soluciones tecnológicas personalizadas para empresas y negocios. Nuestro equipo altamente calificado diseña y desarrolla aplicaciones y sistemas informáticos que impulsan la eficiencia y el crecimiento empresarial. Nos comprometemos a ofrecer soluciones <span className="highlight" style={{ color: '#6a0dad' }}>innovadoras</span> y <span className="highlight" style={{ color: '#6a0dad' }}>escalables</span> que se alineen con los objetivos estratégicos de nuestros clientes.
  </h5>
</div>
<br></br>
<br></br>

<div id="servicios">
< Imagen />
</div>
<br></br>
<br></br>

  

        </Container>
        <Container>
      <div data-aos="zoom-in" className="video-container">
        <ReactPlayer
          url={`${process.env.PUBLIC_URL}/GESTIONA.mp4`}
          width="100%"
          height="auto"
          controls
        />
      </div>
    </Container>
  

        <br></br>
        <br></br>

      </div>
      <div
  data-aos="zoom-in-up"
  style={{
    backgroundImage: `linear-gradient(rgba(106, 13, 160, 0.01), rgba(106, 13, 173, 0.6)), url(${process.env.PUBLIC_URL}/Genius.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100vh', // Ajusta la altura según sea necesario
    textAlign: 'center', // Centra el contenido horizontalmente
  }}
>
  <div data-aos="flip-left" className="image-container"></div>
  <div
    id="Genius"
    data-aos="flip-left"
    className="text-container"
    style={{ margin: 'auto' }} // Centra el texto horizontalmente
  >
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
     <br></br>
    <br></br>
 

    <h1 className='helo'>GENIUS SYSTEM SOFTWARE PARA GESTION DE SUPERMERCADOS Y MINIMERCADOS</h1>
    <br></br>
    <h4 style={h1Style} className={isZoomed ? 'zoomed' : ''} onClick={handleZoom}><FontAwesomeIcon icon={faClipboardList} /> Control de Stock</h4>
    <h4 style={h1Style} className={isZoomed ? 'zoomed' : ''} onClick={handleZoom}><FontAwesomeIcon icon={faChartLine} /> Estadísticas en tiempo Real</h4>
    <h4  style={h1Style} className={isZoomed ? 'zoomed' : ''} onClick={handleZoom}><FontAwesomeIcon icon={faCloud} /> Sistema en la Nube Rápido y Ágil</h4>
    <h4  style={h1Style} className={isZoomed ? 'zoomed' : ''} onClick={handleZoom}><FontAwesomeIcon icon={faFileInvoice} /> Facturación Electrónica por AFIP</h4>
    <h4 style={h1Style} className={isZoomed ? 'zoomed' : ''} onClick={handleZoom}><FontAwesomeIcon icon={faMobileAlt} /> Envío de Ticket de Compra por Whatsapp</h4>
    <h4 style={h1Style} className={isZoomed ? 'zoomed' : ''} onClick={handleZoom}><FontAwesomeIcon icon={faMoneyBill} /> Control de Gastos Diarios</h4>
  </div>

    <br></br>
    <br></br>
<br></br>
<br></br>
<br></br>

<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
















    <br></br>
        <br></br>


</div>
<br></br>
<br></br>

<div  id="Contacto" data-aos="flip-left" className="new-div" style={{ background: 'linear-gradient(135deg, #6a0dad, #9b30ff, #6a0dad)', color: 'white', padding: '50px', borderRadius: '10px', maxWidth: '600px', margin: '0 auto' }}>
<br></br>

  <h1 style={{ textAlign: 'center' }}>Contacto</h1>
  <h4 style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faPhone} /> 341-6663229</h4>
  <h4 style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faEnvelope} /> Info@sistemasgenius.com</h4>
  <h4 style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faMapMarkerAlt} /> Rosario-Argentina 2000</h4>
</div>

  
    </div>

  );
}

export default App;
